<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="姓名" prop="member_keyword">
        <el-input v-model="queryForm.member_keyword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="事件时间" prop="dateRange">
        <el-date-picker v-model="dateRange" @change="handleRecordDateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="风险等级" prop="label">
        <el-select v-model="queryForm.label" placeholder="请选择">
          <el-option label="低风险" :value="1"></el-option>
          <el-option label="中风险" :value="2"></el-option>
          <el-option label="高风险" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理状态" prop="state">
        <el-select v-model="queryForm.state" placeholder="请选择">
          <el-option label="未处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member_info.realname"></el-table-column>
      <el-table-column label="事件时间" prop="record_time"></el-table-column>
      <el-table-column label="风险等级" v-slot="{ row }">
        <el-tag :type="riskLevelDict[row.label].type" size="small">
          {{ riskLevelDict[row.label].label }}
        </el-tag>
      </el-table-column>
      <el-table-column label="记录内容" prop="record_content"></el-table-column>
      <el-table-column label="处理状态">
        <template v-slot="{ row }">
          <el-tag v-if="row.state === 0" type="warning" size="small">未处理</el-tag>
          <el-tag v-else type="success" size="small">已处理</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="处理意见" prop="remark"></el-table-column>
      <el-table-column label="记录人" prop="user_info.name"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getSafetyData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :before-close="closeDialog" :close-on-click-modal="false"
      width="550px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="姓名" prop="member_id">
          <el-select v-model="form.member_id" filterable clearable remote :loading="recordLoading"
            :remote-method="getRecordData" placeholder="请选择" class="el-input_inner--rewrite">
            <el-option v-for="item in recordData" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span><span>{{ item.birthday }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件时间" prop="record_time">
          <el-date-picker v-model="form.record_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="风险等级" prop="label">
          <el-select v-model="form.label" placeholder="请选择">
            <el-option label="低风险" :value="1"></el-option>
            <el-option label="中风险" :value="2"></el-option>
            <el-option label="高风险" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录内容" prop="record_content">
          <el-input v-model="form.record_content" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="处理状态" prop="state">
          <el-radio-group v-model="form.state">
            <el-radio :label="0">未处理</el-radio>
            <el-radio :label="1">已处理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="处理意见" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看记录" :visible.sync="showPreviewDialog" width="500px">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <el-descriptions-item label="姓名">{{ curRowData.member_info.realname }}</el-descriptions-item>
        <el-descriptions-item label="事件时间">{{ curRowData.record_time }}</el-descriptions-item>
        <el-descriptions-item label="风险等级">
          <el-tag :type="riskLevelDict[curRowData.label].type" size="small">
            {{ riskLevelDict[curRowData.label].label }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="记录内容">{{ curRowData.record_content }}</el-descriptions-item>
        <el-descriptions-item label="处理状态">
          <el-tag v-if="curRowData.state === 0" type="warning" size="small">未处理</el-tag>
          <el-tag v-else type="success" size="small">已处理</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="处理意见">{{ curRowData.remark }}</el-descriptions-item>
        <el-descriptions-item label="记录人">{{ curRowData.user_info.name }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'
  import {
    addSafetyDataAPI,
    getSafetyDataAPI,
    updateSafetyDataAPI,
    delSafetyDataAPI,
  } from '@/api/safety.js'

  export default {
    name: 'Safety',
    data() {
      return {
        // 其他依赖数据
        UPLOAD_URL,
        riskLevelDict: {
          1: {
            label: '低风险',
            type: 'primary',
          },
          2: {
            label: '中风险',
            type: 'warning'
          },
          3: {
            label: '高风险',
            type: 'danger'
          }
        },

        recordData: [],
        recordLoading: false,
        // 搜索
        dateRange: [],
        queryForm: {
          page: 1,
          member_keyword: '',
          label: '',
          state: '',
          start: '',
          end: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,
        // 预览
        showPreviewDialog: false,
        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          member_id: '',
          record_time: '',
          record_content: '',
          label: '',
          state: 0,
          remark: '',
          user_id: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          record_time: [{
            required: true,
            message: '记录时间不能为空',
            trigger: 'change'
          }],
          record_content: [{
            required: true,
            message: '记录内容不能为空',
            trigger: 'change'
          }],
          label: [{
            required: true,
            message: '风险等级不能为空',
            trigger: 'blur'
          }],
          state: [{
            required: true,
            message: '处理状态不能为空',
            trigger: 'change'
          }],
          remark: [{
            required: true,
            message: '处理意见不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    created() {
      this.getRecordData()
      this.getSafetyData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getSafetyData()
      },
      // 记录时间选择 --顶部搜索框
      handleRecordDateChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      // 重置按钮
      handleReset() {
        this.queryForm.start = ''
        this.queryForm.end = ''
        this.$refs.queryForm.resetFields()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 删除按钮
      handleDel(id) {
        delSafetyDataAPI(id).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getSafetyData()
          }
        })
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.recordData = [this.curRowData.member_info]
        // 设置form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 提交按钮
      handleSubmit() {
        // 校验表单
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          this.form.user_id = sessionStorage.getItem('systemUserId')
          // 提交
          if (this.dialogTitle === '新增') {
            addSafetyDataAPI(this.form).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getSafetyData()
            }).finally(() => this.submitting = false)
          } else {
            updateSafetyDataAPI(this.curRowData.id, this.form).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getSafetyData()
            }).finally(() => this.submitting = false)
          }
        })
      },
      // 获取档案数据
      getRecordData(keyword = '') {
        this.recordLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            page: 1,
            keyword,
          }).then(res => {
            this.recordData = res.data
            this.recordLoading = false
          })
        }, 300)
      },
      // 获取照护数据
      getSafetyData() {
        this.tableLoading = true

        getSafetyDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
    }
  }
</script>

<style scoped>
</style>